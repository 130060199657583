.main-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  min-height: 100svh;
  // height: 100vh;
  // height: 100svh;
  text-align: center;
  background: url(../assets/images/background.png);
  // background: url(../assets/images/topbg.png) no-repeat top,
  //   url(../assets/images/bottombg.png) no-repeat bottom;
  // // url(../assets/images/bg/heart.png) no-repeat top 0.5rem right -1rem;
  // background-size: 100%, 100%;
  // background-size: cover auto;
  // background-position: top left, right bottom;
  min-height: 100svh;
  // min-height: 100vh;

  header {
    flex-shrink: 0;
    // padding: 0 20px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-lang {
      background-color: #f6e36d;
      border: 1px solid #000;
      border-radius: 0.2rem;
      color: #000;
      padding: 0.2rem;
    }
    .header-logo {
      display: none;
    }
    .desktop-header-logo {
      display: none;
    }
    .logo {
      margin: 0 auto;
    }
    @media screen and (min-width: 768px) {
      .logo {
        display: none;
      }
      .header-logo-bg {
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 130px;
      }
      .desktop-header-logo {
        display: block;
        position: absolute;
        width: 11%;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
      }
    }
    .menu-icon {
      padding-left: 1em;
      position: absolute;
      //right: 20px;
      transition: transform 0.3s;
      cursor: pointer;
      top: 0.5em;
      // display: none;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  footer {
    flex-shrink: 0;
    width: 100%;
    .link {
      color: #2f0743;
      font-family: Myriad Pro;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      text-transform: uppercase;
      cursor: pointer;
      margin: 0 auto;
    }
  }
  .desktop-footer {
    display: none;
  }
  @media screen and (min-width: 768px) {
    .desktop-footer {
      display: block;
    }
    .mobile-footer {
      display: none;
    }
  }
  .content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-bottom: 15%;
    z-index: 1;
  }
}

// .main-layout::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-image: url(../assets/images/header-logo.png);
//   background-repeat: no-repeat;
//   pointer-events: none;
//   background-size: 100%;
// }

// .main-layout::after {
//   content: "";
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 100%;
//   height: 100%;
//   background-image: url(../assets/images/bottom.png);
//   background-size: 100%;
//   background-repeat: no-repeat;
//   background-position: bottom right, bottom center;
//   pointer-events: none;
// }

// .main-layout.gate-page {
//   background: url(../assets//images/gate-bg.png);
//   background-size: 100% 100%;
//   min-height: 100%;
// }

// .main-layout.upload-selfie-page {
//   background: url(../assets//images/gate-bg.png);
//   background-size: 100% 100%;
//   min-height: 100%;
// }

// .main-layout.completed-page {
//   background: url(../assets//images/completed-bg.png);
//   background-size: 100% 100%;
//   min-height: 100%;
// }
.main-layout.gate-page {
  .pack-container {
    background-color: #f3f2e8;
    bottom: 0;
    position: absolute;
    height: 66%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    z-index: 1;
    padding-top: 3em;
  }
}
