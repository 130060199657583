@import "../../styles/global.scss";

.home-page {
  background: url(../../assets/images/Get-started-bg.png) no-repeat center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  height: 100svh;
  overflow: auto;
  text-align: center;
  @include hideScroll;
  @media screen and (min-width: 768px) {
    background: url(../../assets/images/bg-desktop.png) no-repeat center;
    background-size: 100% 100%;
  }

  .logo {
    // width: 50%;
    // width: 60%;
    margin: 0 auto;
    // max-width: 240px;
    max-width: 210px;
    width: 100%;
    // margin-top: .5em;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      width: 70%;
    }
    @media (max-height: 680px) {
      width: 50%;
      margin-top: 0;

      }
  }
  .illustration-desktop {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      width: 45%;
      position: absolute;
      padding-bottom: 1em;
      top: 15%;
      left: 50%;
      transform: translatex(-50%);
    }
  }

  .illustration {
    // width: 100%;
    // width: 60%;
    width: 230px;
    // padding-bottom: 1em;
    // position: relative;
    // top: 5vh;
    @media screen and (min-width: 768px) {
      display: none;
      // width: 20%;
      // position: absolute;
      // padding-bottom: 1em;
      // top: 20%;
      // left: 50%;
      // transform: translatex(-50%);
    }
    @media (max-height: 680px) {
        width: 53%;
        top: 0;
        position: relative;

      }
    
  }


  .cashback-image{
    // margin-top: 2rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    // bottom: 1em;
    @media screen and (min-width: 768px) {
     width: 300px;
      position: absolute;
      bottom: 6em;
      padding-bottom: 3em;
    }
    @media (max-height: 680px) {
      position: absolute;
      bottom: 6em;

      }
  }


  .text-wrapper {
    // .text1 {
    //   color: #2f0743;
    //   text-align: center;
    //   font-family: Graphik;
    //   font-size: 28px;
    //   font-style: italic;
    //   font-weight: 600;
    //   text-transform: uppercase;
    //   margin: 10px auto 0;
    // }

    .centerimage {
      // width: 50%;
      // margin: 10px auto 0;
      // max-width: 240px;
      // width: 65%;
      // max-width: 300px;
      max-width: 300px;
      width: 100%;
    }

    .text2 {
      color: #ed1c24;
      text-align: center;
      font-family: DINPro;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      margin: 4px auto 10px;
    }

    .prizes {
      margin: 10px auto 0;
      width: 150px;
    }
  }
  .error-message {
    color: #ed1c24;
    font-family: poppins;
    font-size: 12px;
  }

  .btn {
    margin: 10px auto;
  }
  .input-group {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    // row-gap: 5px;
    row-gap: 1em;
    align-items: center;
    .language-selection {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 66px;
      border: 1px solid #fff;
      display: inline-flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      color: #fff;
    }
  }
  .get-started-container {
    // position: relative;
    cursor: pointer;
    // width: 308px;
    // height: 60px;
    // flex-shrink: 0;
  }
  .get-started-imag {
    width: 100%;
  }
  .get-started-text {
    position: absolute;
    top: 91%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px; /* Adjust the font size as needed */
    font-family: LexendDeca;
    color: #ffffff; /* Adjust the text color as needed */
    width: 308px;
    height: 60px;
    flex-shrink: 0;
    font-weight: bold;
    border-radius: 8px;
    background: #000;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.23);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .closed{
    font-weight: bold;
    @media screen and (min-width: 768px) {
      // width: 300px;
       position: absolute;
       bottom: 3em;
      //  padding-bottom: 3em;
     }
  }

}
