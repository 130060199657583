@import "../Register/Register";

.reward-phonepey-form {
  box-shadow: -6px 10px 12.7px 0px rgba(0, 0, 0, 0.25);
  background-color: #ef1c26;
  border-radius: 16px;
  padding: 1em;
  width: 90%;
  max-width: 350px;
}
.congratulation-container {
  // padding: 1em;
  color: #fff;
}
.back-btn {
}

.congratulation-title {
  font-family: Karantina;
  color: #fff;
  font-size: 43px;
  font-weight: 700;
  margin: 0;
}
.congratulation-title-sub {
  font-family: LexendDeca;
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  margin-top: 0.5em;
}
.congratulation-amt {
  color: #ffef00;
  font-weight: 700;
  margin: 0;
  font-size: 55px;
}
.congratulation-text {
  font-family: LexendDeca;
}

.redeem-section {
  width: 100%;
  display: flex;
  flex-direction: column;

  .redeem-question {
    color: #fff;
    text-align: center;
    // font-family: $Gotham-Black;
    font-family: LexendDeca;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
  }

  .redeem-options {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-bottom: 1rem;

    button {
      width: 100%;
      display: flex;
      height: 44px;
      padding: 16px 10px;
      justify-content: center;
      align-items: center;
      gap: 186px;
      color: #000;
      text-align: center;
      // font-family: $GothamRnd-Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 96%;
      // border-radius: 52px;
      // background: #091c33;
      outline: none;
      border: none;
      border-radius: 8px;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.73);

      &:active {
        background: #f1c40f;
      }

      &:disabled {
        background: rgba(255, 255, 255, 0.29);
      }
    }
  }
}
