// @font-face {
//   font-family: Afterglow;
//   src: url(../assets/fonts/Afterglow-Regular.ttf);
// }
// @font-face {
//   font-family: DINPro;
//   src: url(../assets/fonts/DINPro-Bold_13934.ttf);
// }
// @font-face {
//   font-family: Graphik;
//   src: url(../assets/fonts/GraphikSemiboldItalic.otf);
// }
// @font-face {
//   font-family: Myriad Pro;
//   font-weight: bold;
//   src: url(../assets/fonts/MYRIADPRO-COND.OTF);
// }
// @font-face {
//   font-family: Myriad Pro;
//   src: url(../assets/fonts/MYRIADPRO-REGULAR.OTF);
// }
@font-face {
  font-family: poppins;
  src: url(../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: SpaceGrotesk;
  src: url(../assets/fonts/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: Karantina;
  src: url(../assets/fonts/Karantina-Bold.ttf);
}

@font-face {
  font-family: LexendDeca;
  font-weight: 400;
  src: url(../assets/fonts/LexendDeca-VariableFont_wght.ttf);
}
// @import "mixins";

@mixin hideScroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0;
  }
}

body {
  margin: 0;
  margin: 0;
  padding: 0;
  // --green: #03a651;
  // background-color: var(--bg-blue);
  font-family: SpaceGrotesk;
  // background-color: #33146f;
  // background-image: url(../assets/images/bg/BG1.png);
  // background-color: #be0000;
  // background: url(../assets//images/completed-bg.png);
  // background: linear-gradient(
  //     90deg,
  //     #96181d 0%,
  //     #a51a20 8%,
  //     #ba1e25 22%,
  //     #c62128 36%,
  //     #cb2229 50%,
  //     #c62128 64%,
  //     #ba1e25 78%,
  //     #a51a20 92%,
  //     #96181d 100%
  //   ),
  //   #f3f2e8;

  background-size: 100% 100%;
}
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.btn {
  border-radius: 8px;
  outline: none;
  border: none;
  padding: 12px 45px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  cursor: pointer;
  &.btn-primary {
    border-radius: 8px;
    background: #000;

    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.23);
    height: 60px;
    width: 200px;
    display: flex;
    // justify-content: space-between;
    justify-content: space-around;
    align-items: center;
    padding: 1em;
    // padding: 0 0 0 2em;
    position: relative;
    font-size: 1em;
    // font-size: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-family: LexendDeca;
  }
  &.back-btn {
    left: auto;
    transform: none;
    margin-top: 1em;
    font-family: LexendDeca;
  }
}
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  text-align: center;
  color: #fff;
  font-family: LexendDeca;
  // font-style: italic;
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          // background-color: white;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    // border: 1px solid #858585;
    // background: #fff;
    background: transparent;
    border: 1.6px solid #f3f2e8;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 4px;
      height: 9px;
      border: solid #ffbc1f;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
select {
  padding: 12px;
  font-size: 13px;
  // text-align: center;
  text-align: left;
  border: none;
  box-sizing: border-box;
  font-family: SpaceGrotesk;
  background: transparent;
  // background: #ffff;
  border-radius: 8px;
  border: 1px solid #ffbc1f;
  color: #000;
  width: 100%;
  outline: none;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  appearance: none;
  &::placeholder {
    color: #454545;
    font-family: SpaceGrotesk;
  }
}

.checkbox-container1 {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  text-align: start;
  color: #fff;
  font-family: SpaceGrotesk;
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          // background-color: white;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    // border: 1px solid #858585;
    // background: #fff;
    background: transparent;
    border: 1.6px solid #f3f2e8;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 4px;
      height: 9px;
      // border: solid #ed1c24;
      border: solid #ffbc1f;

      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
// .checkmark-smoke {
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 18px;
//   width: 18px;
//   border-radius: 2px;
//   border: 1px solid #858585;
//   background: #fff;
//   &:after {
//     content: "";
//     position: absolute;
//     display: none;
//     left: 4px;
//     top: 0px;
//     width: 4px;
//     height: 9px;
//     border: solid #ed1c24;
//     border-width: 0 2px 2px 0;
//     transform: rotate(45deg);
//   }
// }

.input-group {
  margin-bottom: 20px;
  text-align: center;
  margin: 0 auto 15px;
}
.input-label {
  color: #fff;
  float: left;
  font-size: small;
  padding-bottom: 1px;
  // padding-bottom: 0.5em;
}

.error {
  color: #ffba12;
  text-align: center;
  font-family: LexendDeca;
  font-size: 14px;
}

input {
  padding: 12px;
  font-size: 13px;
  // text-align: center;
  text-align: left;
  border: none;
  box-sizing: border-box;
  font-family: LexendDeca;
  background: transparent;
  // background: #ffff;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #000;
  width: 100%;
  outline: none;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  &::placeholder {
    color: #454545;
    font-family: LexendDeca;
  }
}

.grecaptcha-badge {
  bottom: 40px !important;
}
.Toastify__toast-theme--light {
  // background: var(--bg-red) !important;
  // color: white !important;
  &.Toastify__toast--success {
    background: var(--bg-blue);
  }
}
.App {
  height: 100svh;
  // height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  @media screen and (min-width: 768px) {
    display: block;
    margin: 0 auto;
    // max-width: 375px;
    @include hideScroll;
  }
}

// .flag {
//   width: 100%;
//   height: 40px;
//   // animation: flagAnim 100s linear infinite;
//   flex-shrink: 0;
//   background-position: bottom;
//   background-repeat: repeat-x;
//   background-size: contain;
//   &.top {
//     background-image: url(../assets/images/anim/Flag-Animation.gif);
//     transform: rotateZ(180deg);
//   }
//   &.bottom {
//     background-image: url(../assets/images/anim/Flag-Animation.gif);
//   }
// }

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  .popup-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
  }
  .popup-modal {
    position: relative;
    z-index: 1;
    background-color: white;
    // width: 80%;
    max-width: 400px;
    border-radius: 0.5rem;
    max-height: 85vh;
    overflow: auto;
    font-family: SpaceGrotesk;
    .header {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid lightgray;
      position: sticky;
      top: 0;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 20px;
        margin: 0;
      }
    }
    .content {
      padding: 1rem;
      text-align: center;
    }
    .closeBtn {
      position: absolute;
      top: 10px;
      width: 22px;
      right: 10px;
      margin: auto;
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
    }
  }
}
