.contact-us-popup {
  font-family: LexendDeca;
  text-align: center;
  .popup-modal {
    .header {
      border: none;
    }
    .content {
      padding-top: 0;
    }
  }
  .text3 {
    a {
      text-decoration: none;
      color: #000;
      font-family: LexendDeca;
    }
    .text4 {
      color: blue;
      text-decoration: underline;
    }
  }
}
.terms-popup {
  padding: 10px;
  text-align: center;
  ol {
    padding-left: 1em;
    text-align: left;
    font-size: 12px;
  }
  ul {
    padding-left: 0.5em;
  }
}
.howtoredeem-us-popup {
  padding: 10px;
  text-align: center;
  ol {
    text-align: start;
    li {
      margin-bottom: 0.5rem;

      ul {
        margin-bottom: 0.5rem;
      }
    }
  }
}
