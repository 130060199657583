// form {
//   // width: 85%;
//   width: 90%;
//   position: absolute;
//   // top: 50%;
//   top: 48%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }
form {
  min-width: 90%;
  max-width: 95%;
  @media screen and (min-width: 768px) {
    min-width: 400px;
  }
}
.register-page {
  .register-form,
  .otp-form {
    text-align: center;
    // margin: 2em auto;
    // background-color: #210d48;
    box-shadow: -6px 10px 12.7px 0px rgba(0, 0, 0, 0.25);
    background-color: #ef1c26;
    border-radius: 16px;
    padding: 1em 1.5em;
    padding-top: 0;
    .form-title {
      // color: #ffff;
      // text-align: center;
      // font-family: Karantina;
      // font-size: 55px;
      // font-weight: 600;
      // line-height: normal;
      margin: 0.3em 0;
      // padding-top: 0.3em;
      color: #fff;

      -webkit-text-stroke-width: 4;
      -webkit-text-stroke-color: #ef1c26;
      font-family: Karantina;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.12px;
      text-transform: uppercase;
      padding-top: 0.2em;
    }
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: left;
    }
    .input-group {
      .text {
        margin-right: 10px;
      }
      label:not(:first-child) {
        margin-left: 10px;
      }
    }
    .timer-wrapper {
      margin: 30px auto 10px;
      display: flex;
      justify-content: center;
      .timer {
        width: 25px;
        border: 1px solid;
        height: 25px;
        border-radius: 50%;
        font-size: 12px;
        margin-right: 6px;
        color: #fff;
        font-family: SpaceGrotesk;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      span {
        color: #fff;
        font-family: SpaceGrotesk;
        font-size: 12px;
        // font-style: italic;
        font-weight: 600;
        line-height: normal;
        &.link {
          color: #f4b747;
          margin-left: 6px;
          cursor: pointer;
          text-decoration-line: underline;
        }
      }
    }
    .reg-lang {
      .reg-lang-title {
        margin-top: 5px;
        margin-bottom: 5px;
        font-family: Myriad Pro;
      }
    }
    .link {
      color: #f4b747;
      font-family: SpaceGrotesk;
      text-decoration: underline;
    }
  }
  // .register-title {
  //   position: relative;
  //   bottom: 2em;
  // }
  .smoker-text {
    color: #fff;
    font-family: SpaceGrotesk;
    font-size: 14px;
    font-style: italic;
    // font-style: italic;
    // font-weight: 600;
    // line-height: normal;
  }
}
.terms-link {
  color: #f4b747;
}
